import React from 'react';
import ComparisonSection from '../components/ComparisonSection';
import '../../home/home.css'
import { FaClipboardCheck, FaLightbulb, FaShareAlt } from 'react-icons/fa';

export default function GoogleForms() {
    const features = [
        {
            name: "Multiple Question Types",
            description: "Provide a variety of questions on the form",
            tetherform: true,
            tetherformNote: "",
            competitor: true,
            competitorNote: ""
        },
        {
            name: "Templates",
            description: "Create surveys from pre-built questions",
            tetherform: true,
            tetherformNote: "",
            competitor: true,
            competitorNote: ""
        },
        {
            name: "Survey Customization",
            description: "Add colors, descriptions, and titles",
            tetherform: true,
            tetherformNote: "",
            competitor: true,
            competitorNote: ""
        },
        {
            name: "Exportable Data",
            description: "Send raw data to a spreadsheet",
            tetherform: true,
            tetherformNote: "",
            competitor: true,
            competitorNote: ""
        },
        {
            name: "Email Notifications",
            description: "Send results & reports to your inbox",
            tetherform: true,
            tetherformNote: "10 per form",
            competitor: true,
            competitorNote: "Only 1 per form"
        },
        {
            name: "Rapid Recommendations",
            description: "Personalized recommendations based on answers",
            tetherform: true,
            tetherformNote: "",
            competitor: false,
            competitorNote: ""
        },
        {
            name: "Survey Insights Report",
            description: "Sentiment analysis, trends, and other patterns",
            tetherform: true,
            tetherformNote: "",
            competitor: false,
            competitorNote: ""
        },
        {
            name: "Anonymous Surveys",
            description: "100% protection of responder's identity",
            tetherform: true,
            tetherformNote: "",
            competitor: false,
            competitorNote: "Google account required"
        },
        {
            name: "QR Code Generator",
            description: "Automatically create a QR code for survey access",
            tetherform: true,
            tetherformNote: "",
            competitor: false,
            competitorNote: ""
        },
        {
            name: "Survey Drafts",
            description: "Share preview version of form with your team",
            tetherform: true,
            tetherformNote: "",
            competitor: false,
            competitorNote: ""
        },
    ]
    return (
        <div className="comparison-page-content">
            <div className="comparison-page-intro">
                <h2>Why choose Tetherform over Google Forms?</h2>
                <div className="home-why-choose-grid">
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaClipboardCheck />
                        </div>
                        <h3>Board-Ready Reports</h3>
                        <p>Justify decisions with confidence using automatically generated reports that include recommendations for your board.</p>
                    </div>
                    
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaLightbulb />
                        </div>
                        <h3>Member Insights</h3>
                        <p>Find out what your members really want with sentiment analysis and trend detection that Google Forms can't provide.</p>
                    </div>
                    
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaShareAlt />
                        </div>
                        <h3>Simple Distribution</h3>
                        <p>Share surveys effortlessly with automatically created QR codes and draft survey links for team collaboration.</p>
                    </div>
                </div>
                <p className="comparison-page-verdict"><strong>Verdict</strong><br/>While Google Forms is a great tool for basic surveys, Tetherform is purpose-built for professional communities that need automated insights and actionable recommendations.</p>
            </div>

            <ComparisonSection 
                title="Compare Features" 
                features={features}
                competitorName='Google Forms'
            />

        </div>
    );
} 