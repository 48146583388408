import React from 'react';
import ComparisonSection from '../components/ComparisonSection';
import { FaRobot, FaUsers, FaRegClock, FaDollarSign } from 'react-icons/fa';
import RatingComparison from '../components/RatingComparison';

export default function Qualtrics() {
    const features = {
        usability: [
            {
                name: "Quick Setup",
                description: "Get started in minutes without training",
                tetherform: true,
                tetherformNote: "5-minute setup",
                competitor: false,
                competitorNote: "Complex implementation"
            },
            {
                name: "Intuitive Interface",
                description: "User-friendly design for all skill levels",
                tetherform: true,
                competitor: false,
                competitorNote: "Enterprise complexity"
            }
        ],
        pricing: [
            {
                name: "Transparent Pricing",
                description: "Clear, predictable pricing structure",
                tetherform: true,
                competitor: false,
                competitorNote: "Custom quotes only"
            },
            {
                name: "No Long-term Contracts",
                description: "Flexible month-to-month billing",
                tetherform: true,
                competitor: false,
                competitorNote: "Annual contracts required"
            }
        ],
        community: [
            {
                name: "Community Focus",
                description: "Features built for professional communities",
                tetherform: true,
                competitor: false,
                competitorNote: "General enterprise focus"
            },
            {
                name: "Automated Insights",
                description: "AI-powered community recommendations",
                tetherform: true,
                competitor: false
            }
        ]
    };

    const ratings = {
        "Ease of Use": {
            tetherform: 4.8,
            competitor: 3.8
        },
        "Customer Support": {
            tetherform: 4.9,
            competitor: 3.9
        },
        "Features & Functionality": {
            tetherform: 4.7,
            competitor: 4.5
        },
        "Value for Money": {
            tetherform: 4.8,
            competitor: 3.2
        }
    };

    return (
        <div className="comparison-page-content">
            <div className="comparison-page-intro">
                <h2>Why Choose Tetherform Over Qualtrics?</h2>
                <p>
                    While Qualtrics offers enterprise-level research tools, Tetherform provides a more 
                    accessible, community-focused solution with automated insights and a faster time to value.
                </p>
                
                <div className="comparison-page-benefits">
                    <div className="comparison-page-benefit">
                        <FaRegClock className="comparison-page-benefit-icon"/>
                        <h3>Quick Setup</h3>
                        <p>Start collecting feedback in minutes</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaDollarSign className="comparison-page-benefit-icon"/>
                        <h3>Simple Pricing</h3>
                        <p>Transparent, predictable costs</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaRobot className="comparison-page-benefit-icon"/>
                        <h3>AI Analysis</h3>
                        <p>Automated insights and recommendations</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaUsers className="comparison-page-benefit-icon"/>
                        <h3>Community Focus</h3>
                        <p>Built for professional communities</p>
                    </div>
                </div>
            </div>

            <RatingComparison ratings={ratings} />

            <ComparisonSection 
                title="Ease of Use" 
                features={features.usability}
            />
            
            <ComparisonSection 
                title="Pricing & Flexibility" 
                features={features.pricing}
            />

            <ComparisonSection 
                title="Community Features" 
                features={features.community}
            />
        </div>
    );
} 