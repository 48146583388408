import React from 'react'
import { FaList, FaStar, FaCheckSquare, FaFont, FaTimes, FaCopy, FaHeading } from 'react-icons/fa'
import { Draggable } from 'react-beautiful-dnd'
import '../QuestionCanvas.css'

// Define supported element types and their configurations
const elementTypes = {
    'section-heading': {
        icon: FaHeading,
        label: 'Section Heading',
        getDisplayText: (element) => {
            const content = element.content?.trim() || 'Section Heading';
            return content
        },
        getDisplayLabel: () => 'Section Heading',
        className: 'question'
    },
    'multiple-choice': {
        icon: FaList,
        label: 'Multiple Choice',
        getDisplayText: (element) => element.question?.trim() || 'Multiple Choice question',
        getDisplayLabel: (index, questionNumber) => `Question ${questionNumber}`,
        className: 'question'
    },
    'rating': {
        icon: FaStar,
        label: 'Rating',
        getDisplayText: (element) => element.question?.trim() || 'Rating question',
        getDisplayLabel: (index, questionNumber) => `Question ${questionNumber}`,
        className: 'question'
    },
    'select-multiple': {
        icon: FaCheckSquare,
        label: 'Select Multiple',
        getDisplayText: (element) => element.question?.trim() || 'Select Multiple question',
        getDisplayLabel: (index, questionNumber) => `Question ${questionNumber}`,
        className: 'question'
    },
    'text': {
        icon: FaFont,
        label: 'Text',
        getDisplayText: (element) => element.question?.trim() || 'Text question',
        getDisplayLabel: (index, questionNumber) => `Question ${questionNumber}`,
        className: 'question'
    }
};

export default function FormCanvasItem({ element, index, onElementClick, onDeleteIconClick, onDuplicateIconClick, isNew, questionNumber }) {
    // Check if this is a supported element type
    const elementConfig = elementTypes[element.type];
    if (!elementConfig) {
        console.warn(`Unsupported form element type: ${element.type}`);
        return null;
    }

    const { icon: ElementIcon, getDisplayText, getDisplayLabel, className } = elementConfig;
    
    const handleClick = (e, provided, element) => {
        if (!provided.dragHandleProps.isDragging) {
            onElementClick(e, element);
        }
    };
    return (
        <Draggable draggableId={element.id} index={index}>
            {(provided, snapshot) => (
                <div 
                    className={`question-canvas-question-item ${className} ${snapshot.isDragging ? 'is-dragging' : ''} ${isNew ? 'highlight-new' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={(e) => handleClick(e, provided, element)}
                >
                    <div className="question-canvas-question-icon-container">
                        <ElementIcon className="question-canvas-question-icon" />
                    </div>
                    <div className="question-canvas-question-text-container">
                        <span className="question-canvas-question-number">
                            {getDisplayLabel(index, questionNumber)}
                        </span>
                        <span className="question-canvas-question-text">{getDisplayText(element)}</span>
                    </div>
                    <div 
                        className="question-canvas-delete-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteIconClick(e, element);
                        }}
                    >
                        <FaTimes />
                    </div>
                    <div 
                        className="question-canvas-duplicate-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDuplicateIconClick(e, element, index);
                        }}
                    >
                        <FaCopy />
                    </div>
                </div>
            )}
        </Draggable>
    )
}

// Export the element types configuration for use in other components
export { elementTypes }; 