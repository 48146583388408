import React, { useState, useEffect, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import './FormBuilderOptions.css';
import ToggleSwitch from '../gizmos/ToggleSwitch';
import { MdOpenInNew } from "react-icons/md";

const FormBuilderDesignOptions = ({ formState, onDesignUpdate, openPreview }) => {
    const [primaryColor, setPrimaryColor] = useState(formState.design_primary_color || '#007bff');
    const [isDarkMode, setIsDarkMode] = useState(formState.design_dark_mode || false);
    const [includeLogo, setIncludeLogo] = useState(formState.design_show_logo || false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [hexInput, setHexInput] = useState(formState.design_primary_color || '#007bff');
    const colorPickerRef = useRef(null);
    const [formDisplayName, setFormDisplayName] = useState(formState.form_display_name || '');
    const [formIntroduction, setFormIntroduction] = useState(formState.introduction_message || '');

    useEffect(() => {
        setHexInput(primaryColor);
    }, [primaryColor]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleColorChange = (color) => {
        setPrimaryColor(color);
        setHexInput(color);
        onDesignUpdate({ design_primary_color: color, design_dark_mode: isDarkMode });
    };

    const handleHexInputChange = (e) => {
        const value = e.target.value;
        setHexInput(value);
        if (value.length === 7 && /^#[0-9A-Fa-f]{6}$/.test(value)) {
            setPrimaryColor(value);
            onDesignUpdate({ design_primary_color: value, design_dark_mode: isDarkMode });
        }
    };

    const toggleDarkMode = (newDarkModeSetting) => {
        setIsDarkMode(newDarkModeSetting);
        onDesignUpdate({ design_primary_color: primaryColor, design_dark_mode: newDarkModeSetting });
    };

    const toggleShowLogo = (newLogoSetting) => {
        setIncludeLogo(newLogoSetting);
        onDesignUpdate({ design_show_logo: newLogoSetting });
    };

    const toggleColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleFormDisplayNameChange = (e) => {
        const newValue = e.target.value;
        setFormDisplayName(newValue);
        
        // Debounce the update
        const timer = setTimeout(() => {
            onDesignUpdate({ form_display_name: newValue.trim() });
        }, 500);

        return () => clearTimeout(timer);
    };

    const handleFormIntroductionChange = (e) => {
        const newValue = e.target.value;
        setFormIntroduction(newValue);
        
        // Debounce the update
        const timer = setTimeout(() => {
            onDesignUpdate({ introduction_message: newValue.trim() });
        }, 500);

        return () => clearTimeout(timer);
    };

    return (
        <div className="form-builder-design-options">
            <div className="form-builder-design-options-header-row">
                <h2>Design Options</h2>
                <div className="form-builder-design-options-header-text-button" onClick={openPreview}>Open preview <MdOpenInNew/></div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Form Display Name</h4>
                    <p>The name of the form that will be seen by the user.  Leave blank to use the name associated with this form in Tetherform.</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <input type="text" value={formDisplayName} onChange={handleFormDisplayNameChange} className="form-builder-design-options-input" placeholder="Enter form display name"/>
                </div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Form Description</h4>
                    <p>Provide an introduction to form takers</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <textarea 
                        className="form-builder-design-options-textarea"
                        placeholder="Enter form description"
                        rows="8"
                        maxLength={800}
                        value={formIntroduction}
                        onChange={handleFormIntroductionChange}
                    ></textarea>
                </div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Main Color</h4>
                    <p>Select or customize your main color</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="form-builder-color-picker-container" ref={colorPickerRef}>
                        <div 
                            className="form-builder-color-preview" 
                            style={{ backgroundColor: primaryColor }}
                            onClick={toggleColorPicker}
                        ></div>
                        {showColorPicker && (
                            <div className="form-builder-color-picker-popover">
                                <HexColorPicker color={primaryColor} onChange={handleColorChange} />
                                <input
                                    type="text"
                                    value={hexInput}
                                    onChange={handleHexInputChange}
                                    className="form-builder-hex-input"
                                    maxLength="7"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Show Organization Logo</h4>
                    <p>Display your organization's logo above the form, if available.</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <ToggleSwitch isSelected={includeLogo} setIsSelected={toggleShowLogo} />
                </div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Dark Mode</h4>
                    <p>Use a darker color scheme throughout the form</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <ToggleSwitch isSelected={isDarkMode} setIsSelected={toggleDarkMode} />
                </div>
            </div>
        </div>
    );
};

export default FormBuilderDesignOptions;
