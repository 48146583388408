import React from 'react'
import './StackedBarChart.css'

export default function StackedBarChart({ data, blacklist = [] }) {
    // Filter out blacklisted items and zero values
    const filteredData = Object.entries(data)
        .filter(([key, value]) => !blacklist.includes(key) && value > 0)
        .sort((a, b) => b[1] - a[1]); // Sort by value descending

    if (filteredData.length === 0) return null;

    // Calculate total for percentages
    const total = filteredData.reduce((sum, [_, value]) => sum + value, 0);

    // Color mapping for up to 3 items
    const colors = {
        0: 'var(--color-brand)',
        1: 'var(--color-red)',
        2: 'var(--color-links)'
    };

    // Calculate cumulative percentages for positioning
    let cumulative = 0;
    const segments = filteredData.map(([key, value], index) => {
        const percentage = (value / total) * 100;
        const segment = {
            key,
            value,
            percentage,
            startPosition: cumulative,
            color: colors[index]
        };
        cumulative += percentage;
        return segment;
    });

    return (
        <div className="stacked-bar-chart">
            <div className="stacked-bar-container">
                {segments.map((segment) => (
                    <div
                        key={segment.key}
                        className="stacked-bar-segment"
                        style={{
                            width: `${segment.percentage}%`,
                            left: `${segment.startPosition}%`,
                            backgroundColor: segment.color
                        }}
                    >
                        <span className="segment-label">{Math.round(segment.percentage)}%</span>
                    </div>
                ))}
            </div>
            <div className="stacked-bar-legend">
                {segments.map((segment) => (
                    <div key={segment.key} className="legend-item">
                        <div className="legend-left">
                            <div 
                                className="legend-color-box" 
                                style={{ backgroundColor: segment.color }}
                            />
                            <span className="legend-key">{segment.key}</span>
                        </div>
                        <div className="legend-right">
                            <span className="legend-percentage">
                                {Math.round(segment.percentage)}%
                            </span>
                            <span className="legend-value">
                                ({segment.value})
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
