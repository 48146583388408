import React, { useState, useRef, useEffect } from 'react'
import ModalWrapper from '../ModalWrapper'
import OrgLogo from '../../gizmos/OrgLogo';
import { updateOrganizationLogo } from '../../../api';

export default function ModalOrgSettingsChangeLogo({ close, organization, currentUrl }) {
    const hiddenFileInput = useRef(null)
    const [image, setImage] = useState(null);
    const [proposedFile, setProposedFile] = useState(null);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false)
    const [proposedProfilePic, setProposedProfilePic] = useState(() => {
        if (currentUrl) {
            return currentUrl
        } else {
            return false
        }
    })
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      setProposedFile(file)
  
      // Validate file type
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        setError("File must be a PNG or JPG");
        setImage(null);
        return;
      }
  
      // Validate file size (under 2.5MB)
      const maxSize = 2.5 * 1024 * 1024; // 2.5MB
      if (file.size > maxSize) {
        setError("File size must be under 2.5MB");
        setImage(null);
        return;
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setError(null);
      };
      reader.readAsDataURL(file);
    };
    const promptFileUpload = e => {
        hiddenFileInput.current.click()
    }
    const uploadToServer = () => {
        try {
            setIsUploading(true)
            const formData = new FormData()
            formData.append('logo', proposedFile)
            updateOrganizationLogo(formData, organization._id)
            .then((res) => {
                setIsUploading(false)
                console.log(res)
            })
            .catch((err) => {
                setIsUploading(false)
                setError("Something went wrong changing the image, and we aren't sure why. Try a different image or come back later.")
            })
        } catch (error) {
            
        }
    }
    useEffect(() => {
        if (image) {
            setProposedProfilePic(image)
        }
    }, [image])
    return (
        <ModalWrapper title="Update organization logo" close={close} subtitle="Change the logo your organization can display on forms" >
            {error && <p className="error-message">{error}</p>}
            {proposedProfilePic && (
            <div className="preview-container">
                <OrgLogo logoUrl={proposedProfilePic} organizationName={false} widthSizePixels={300} />
            </div>
            )}
            {!proposedProfilePic &&
            <div className="preview-container" style={{boxShadow: "none"}}>
                <OrgLogo logoUrl={false} organizationName={false} widthSizePixels={200} heightSizePixels={200} />
            </div>
            }
            {!isUploading &&
            <>
            <form className="modals-org-settings-interactive-form">
                <label htmlFor="profile-pic" className="modals-org-settings-button" onClick={promptFileUpload}>Update organization logo</label>
                <input type="file" style={{display: "none"}} accept=".png,.jpg" ref={hiddenFileInput} htmlFor="profile-pic" onChange={handleFileChange}/>
                <div style={{marginTop: "4px", fontSize: "14px", textAlign: "center"}}><em>File size must be less than 2MB</em></div>
            </form>
            <div className="modals-org-settings-save-cancel">
                <span onClick={uploadToServer}>Save changes</span>
                <span onClick={close}>Cancel</span>
            </div>
            </>
            }
            {isUploading && <div className="modals-org-settings-save-cancel">Saving...</div> }
        </ModalWrapper>
    )
}
