import React from 'react';
import ComparisonSection from '../components/ComparisonSection';
import { FaRobot, FaChartBar, FaPaintBrush, FaLock } from 'react-icons/fa';
import RatingComparison from '../components/RatingComparison';

export default function Jotform() {
    const features = {
        analytics: [
            {
                name: "AI-Powered Analytics",
                description: "Automatically analyze responses and generate insights",
                tetherform: true,
                tetherformNote: "Built-in AI analysis",
                competitor: false,
                competitorNote: "Basic reporting only"
            },
            {
                name: "Automated Recommendations",
                description: "Get actionable suggestions based on responses",
                tetherform: true,
                competitor: false
            },
            {
                name: "Sentiment Analysis",
                description: "Understand emotional context of responses",
                tetherform: true,
                tetherformNote: "Real-time analysis",
                competitor: false
            }
        ],
        community: [
            {
                name: "Community-Focused Templates",
                description: "Pre-built templates for professional communities",
                tetherform: true,
                competitor: false,
                competitorNote: "Generic templates only"
            },
            {
                name: "Unlimited Team Members",
                description: "Collaborate with your entire organization",
                tetherform: true,
                competitor: false,
                competitorNote: "Paid per user"
            }
        ],
        security: [
            {
                name: "Enterprise-Grade Security",
                description: "Advanced security features for sensitive data",
                tetherform: true,
                competitor: true
            },
            {
                name: "GDPR Compliance",
                description: "Full compliance with data protection regulations",
                tetherform: true,
                competitor: true
            }
        ]
    };

    const ratings = {
        "Ease of Use": {
            tetherform: 4.8,
            competitor: 4.4
        },
        "Customer Support": {
            tetherform: 4.9,
            competitor: 4.0
        },
        "Features & Functionality": {
            tetherform: 4.7,
            competitor: 4.1
        },
        "Value for Money": {
            tetherform: 4.8,
            competitor: 3.9
        }
    };

    return (
        <div className="comparison-page-content">
            <div className="comparison-page-intro">
                <h2>Why Choose Tetherform Over Jotform?</h2>
                <p>
                    While Jotform offers form building capabilities, Tetherform provides specialized 
                    features designed specifically for professional communities, including AI-powered 
                    analytics and automated insights.
                </p>
                
                <div className="comparison-page-benefits">
                    <div className="comparison-page-benefit">
                        <FaRobot className="comparison-page-benefit-icon"/>
                        <h3>AI-Powered Analysis</h3>
                        <p>Get instant insights without manual data crunching</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaChartBar className="comparison-page-benefit-icon"/>
                        <h3>Actionable Insights</h3>
                        <p>Receive clear recommendations based on responses</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaPaintBrush className="comparison-page-benefit-icon"/>
                        <h3>Simple Customization</h3>
                        <p>Brand your surveys without technical expertise</p>
                    </div>
                    <div className="comparison-page-benefit">
                        <FaLock className="comparison-page-benefit-icon"/>
                        <h3>Enterprise Security</h3>
                        <p>Keep your community data safe and secure</p>
                    </div>
                </div>
            </div>

            <RatingComparison ratings={ratings} />

            <ComparisonSection 
                title="Analytics & Insights" 
                features={features.analytics}
            />
            
            <ComparisonSection 
                title="Community Features" 
                features={features.community}
            />

            <ComparisonSection 
                title="Security & Compliance" 
                features={features.security}
            />
        </div>
    );
} 