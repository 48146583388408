import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Comparisons.css';
import Footer from '../footer/Footer';
import ComparisonFooter from './ComparisonFooter';
import GoogleForms from './competitors/GoogleForms';
import Jotform from './competitors/Jotform';
import SurveyMonkey from './competitors/SurveyMonkey';
import Qualtrics from './competitors/Qualtrics';

export default function ComparisonPage() {
    const { competitorName } = useParams();
    const navigate = useNavigate();

    const competitors = {
        'google-forms': {
            name: 'Google Forms',
            component: GoogleForms,
            title: 'Google Forms doesn\'t care about your members',
            subtitle: (
            <div>
                <p>It doesn't cost anything, but Google Forms is not as affordable as you think. For good results, you have to do everything yourself:
                    <ul>
                        <li>Writing questions</li>
                        <li>Exporting data</li>
                        <li>Analyzing responses</li>
                    </ul>
                </p>
                <p style={{textDecoration: "underline"}}><strong>You're saving money but wasting (valuable) time.</strong></p>
                <p>Only Tetherform generates personalized insights and recommendations that help you understand what your members want.</p>
            </div>
            ),
            url: "https://storage.googleapis.com/tetheros-public/tetherform_landing/compare_google_forms.jpeg"
        },
        'jotform': {
            name: 'Jotform',
            component: Jotform,
            title: 'Tetherform vs Jotform'
        },
        'survey-monkey': {
            name: 'SurveyMonkey',
            component: SurveyMonkey,
            title: 'SurveyMonkey is way more expensive than Tetherform',
            subtitle: (
            <div>
                <p><strong style={{textDecoration: "underline"}}>Your budget isn't a zoo</strong>.</p>
                <p>SurveyMonkey doesn't understand your members, and they'll make you pay for features you don't even need:
                    <ul>
                        <li>Single Sign-On Integration</li>
                        <li>Offline Survey Collection</li>
                        <li>Kiosk Mode</li>
                        <li>Advanced API Access</li>
                        <li>Payment Gateway Infrastructure</li>
                    </ul>
                </p>
                <p>Only Tetherform generates personalized insights and recommendations that help you understand what your members want.</p>
            </div>
            ),
            url: "https://storage.googleapis.com/tetheros-public/tetherform_landing/compare_surveymonkey.jpeg"
        },
        'qualtrics': {
            name: 'Qualtrics',
            component: Qualtrics,
            title: 'Tetherform vs Qualtrics'
        }
    };

    useEffect(() => {
        if (!competitors[competitorName]) {
            navigate('/');
            return;
        }
        window.scrollTo(0, 0);
        document.title = competitors[competitorName].title;
    // eslint-disable-next-line
    }, [competitorName, navigate]);

    if (!competitors[competitorName]) return null;

    const CompetitorComponent = competitors[competitorName].component;
    const competitorUrl = competitors[competitorName].url
    const pageTitle = competitors[competitorName].title

    return (
        <div className="comparison-page-container">
            <div className="comparison-page-hero">
                <div className="comparison-page-hero-wrapper">
                    <div className="comparison-page-hero-content">
                        <h1>{pageTitle}</h1>
                        {competitors[competitorName].subtitle}
                        <button 
                            className="comparison-page-cta-button"
                            onClick={() => navigate('/register')}
                        >
                            Start Free Trial
                        </button>
                    </div>
                    <div className="comparison-page-hero-image-container">
                        <img 
                            src={competitorUrl}
                            alt={`Comparing Tetherform to ${competitorName}`}
                            className="comparison-page-hero-image"
                        />
                    </div>
                </div>
            </div>

            <CompetitorComponent />
            <ComparisonFooter />
            <Footer />
        </div>
    );
}
