import React, { useState } from 'react'
import './OrgSettings.css'
import { getAccountManagementUrl } from '../../api'
import { useOrganization } from '../../context/OrganizationContext'
import { useAuth } from '../../context/AuthContext'
import OrganizationSidebar from '../organization_sidebar/OrganizationSidebar'
import OrgLogo from '../gizmos/OrgLogo'
import ModalOrgSettingsChangeLogo from '../modals/organization_settings/ModalOrgSettingsChangeLogo'

export default function OrgSettings() {
    const { organizationId, organization, loading, error } = useOrganization()
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingLogo, setIsUpdatingLogo] = useState(false)
    const [managementError, setManagementError] = useState(null)

    const handleManagePlan = async () => {
        setIsLoading(true)
        setManagementError(null)
        try {
            const response = await getAccountManagementUrl(organizationId)
            if (response.data && response.data.checkoutSessionUrl) {
                window.location.href = response.data.checkoutSessionUrl
            } else {
                setManagementError('Unable to get management URL')
            }
        } catch (err) {
            setManagementError('An error occurred while fetching the management URL')
        } finally {
            setIsLoading(false)
        }
    }

    if (loading || !organization || !user) {
        return <div>Loading organization details...</div>
    }
    console.log(organization)

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <div className="organization-settings">
            {isUpdatingLogo && <ModalOrgSettingsChangeLogo close={() => setIsUpdatingLogo(false)} organization={organization} currentUrl={organization.organization_profile_picture_url} />}
            <OrganizationSidebar/>
            <div className="organization-settings-main">
                <h1>Settings</h1>
                <div className="organization-settings-sections">
                    <div className="organization-settings-section">
                        <h3>Basic Information</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Name</div>
                            <div className="organization-settings-section-value">{organization.name}</div>
                        </div>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Email</div>
                            <div className="organization-settings-section-value">{user.email}</div>
                        </div>
                    </div>
                    <div className="organization-settings-section">
                        <h3>Usage</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Total responses</div>
                            <div className="organization-settings-section-value">{organization.total_responses} / {organization.monthly_responses}</div>
                        </div>
                    </div>
                    <div className="organization-settings-section">
                        <h3>Account</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">License</div>
                            {organization.monthly_responses  ? <div className="organization-settings-section-value">{organization.monthly_responses} responses / mo</div> : <div className="organization-settings-section-value">5</div>}
                        </div>
                        <div className="organization-settings-manage-plan" onClick={handleManagePlan} disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Manage Subscription Plan'}
                        </div>
                    </div>
                    <div className="organization-settings-section">
                        <h3>Branding</h3>
                        <div className="organization-setting-row" style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div className="organization-settings-section-key">Logo</div>
                            <OrgLogo logoUrl={organization.organization_profile_picture_url} widthSizePixels={80} organizationName={organization.name} />
                        </div>
                        <div className="organization-settings-manage-logo" onClick={() => setIsUpdatingLogo(true)}>
                            Change logo
                        </div>
                    </div>
                </div>
                {managementError && <p className="error-message">{managementError}</p>}
                {!loading &&
                <>
                    {organization.monthly_responses <= organization.total_responses && <p className="error-message" style={{backgroundColor: "var(--color-extra-dark)", color: "var(--color-brand)"}}>You are out of responses for the month. Consider upgrading your plan!</p>}
                </>
                }
            </div>
        </div>
    )
}