import React, { useState, useRef, useEffect, useCallback } from 'react'
import './OrgForms.css'
import { useOrganization } from '../../context/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../loaders/Loader';
import OrganizationSidebar from '../organization_sidebar/OrganizationSidebar';
import { FaPlus, FaTimes, FaSearch, FaChevronDown, FaCheck, FaChevronRight, FaThLarge, FaList } from 'react-icons/fa';
import ModalOrgNewForm from './ModalOrgNewForm';
import ModalOrgDeleteForm from './ModalOrgDeleteForm';
import { createNewForm, duplicateForm, updateFormDraft } from '../../api';
import { formatDistanceToNow } from 'date-fns';

export default function OrgForms() {
    const navigate = useNavigate()
    const { organization, organizationIsLoading, forms, fetchOrganizationDetails } = useOrganization()
    const [showCreateNewForm, setShowCreateNewForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formToDelete, setFormToDelete] = useState(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, formId: null });
    const contextMenuRef = useRef(null);
    const [activeFilters, setActiveFilters] = useState({
        status: [],
        search: '',
        dateRange: null
    });
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const statusDropdownRef = useRef(null);
    const [collapsedSections, setCollapsedSections] = useState({
        Draft: false,
        Live: false,
        Closed: false
    });
    const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
    
    const filteredForms = forms.filter(form => {
        const formStatus = form.schedule_end && new Date() > new Date(form.schedule_end) 
            ? 'Closed' 
            : form.status === 'active' ? 'Live' : 'Draft';
            
        const matchesStatus = activeFilters.status.length === 0 || 
            activeFilters.status.includes(formStatus);
        
        const matchesSearch = !activeFilters.search || 
            form.title.toLowerCase().includes(activeFilters.search.toLowerCase());
        
        return matchesStatus && matchesSearch;
    });

    const handleStatusFilter = (status) => {
        setActiveFilters(prev => ({
            ...prev,
            status: prev.status.includes(status) 
                ? prev.status.filter(s => s !== status)
                : [...prev.status, status]
        }));
    };

    const handleSearchChange = (e) => {
        setActiveFilters(prev => ({
            ...prev,
            search: e.target.value
        }));
    };

    const removeFilter = (type, value) => {
        setActiveFilters(prev => ({
            ...prev,
            [type]: type === 'status' 
                ? prev.status.filter(s => s !== value)
                : ''
        }));
    };

    const handleNewForm = () => {
        navigate(`/o/${organization._id}/forms/create`);
    };

    const submitNewForm = (formName) => {
        createNewForm(organization._id, { title: formName, status: "draft", structure: {} })
        .then((res) => {
            if (res.data && res.data.form && res.data.form._id) {
                navigate(`/o/${organization._id}/forms/${res.data.form._id}`)
                setShowCreateNewForm(false)
            }
        })
        .catch(() => {
            setShowCreateNewForm(false)
        })
    }

    const handleContextMenu = useCallback((e, formId) => {
        e.preventDefault();
        setContextMenu({ visible: true, x: e.clientX, y: e.clientY, formId });
    }, []);

    const handleClickOutside = useCallback((e) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
            setContextMenu(prev => ({ ...prev, visible: false }));
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleEditForm = () => {
        navigate(`/o/${organization._id}/forms/${contextMenu.formId}`);
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const handleDuplicateForm = async () => {
        try {
            await duplicateForm(contextMenu.formId);
            await fetchOrganizationDetails(organization._id);
            setContextMenu(prev => ({ ...prev, visible: false }));
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    };

    const handleDeleteForm = async () => {
        const formToDelete = forms.find(form => form._id === contextMenu.formId);
        if (formToDelete) {
            setFormToDelete(formToDelete);
            setShowDeleteForm(true);
        }
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const confirmDeleteForm = async () => {
        setIsDeleting(true);
        try {
            await updateFormDraft(formToDelete._id, { status: 'deleted' });
            await fetchOrganizationDetails(organization._id);
            setShowDeleteForm(false);
        } catch (error) {
            console.error('Error deleting form:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setShowStatusDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const groupedForms = useCallback(() => {
        const grouped = {
            Live: [],
            Draft: [],
            Closed: []
        };
        
        forms.forEach(form => {
            const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
            const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
            grouped[status].push(form);
        });
        
        return grouped;
    }, [forms]);

    const toggleSection = (status) => {
        setCollapsedSections(prev => ({
            ...prev,
            [status]: !prev[status]
        }));
    };

    const getRecentlyModifiedForms = useCallback(() => {
        return [...forms]
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .slice(0, 4);
    }, [forms]);

    const renderFormsList = (forms) => {
        if (viewMode === 'list') {
            return (
                <div className="org-forms-table-wrapper">
                    <table className="org-forms-table">
                        <thead>
                            <tr>
                                <th>Form Name</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th>Total Responses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form) => {
                                const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
                                const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
                                return (
                                    <tr 
                                        key={form._id}
                                        onClick={(e) => {
                                            if (!contextMenu.visible) {
                                                navigate(`/o/${organization._id}/forms/${form._id}`)
                                            }
                                        }}
                                        onContextMenu={(e) => handleContextMenu(e, form._id)}
                                        className="org-forms-table-row"
                                    >
                                        <td>
                                            <div className="table-form-title">
                                                {form.title}
                                            </div>
                                        </td>
                                        <td>
                                            <span className={`form-status badge-${status.toLowerCase()}`}>
                                                {status}
                                            </span>
                                        </td>
                                        <td>{new Date(form.createdAt).toLocaleDateString()}</td>
                                        <td>{form.response_count || 0}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className="org-forms-grid">
                {forms.map((form) => {
                    const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
                    const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
                    return (
                        <div 
                            key={form._id} 
                            className="org-forms-item" 
                            onClick={(e) => {
                                if (!contextMenu.visible) {
                                    navigate(`/o/${organization._id}/forms/${form._id}`)
                                }
                            }}
                            onContextMenu={(e) => handleContextMenu(e, form._id)}
                        >
                            {status === "Live" && <div className="org-forms-live-indicator"></div>}
                            <span className={`form-status badge-${status.toLowerCase()}`}>{status}</span>
                            <h3 className="form-title">{form.title}</h3>
                            {form.schedule_start && form.schedule_end ? (
                                <p>{new Date(form.schedule_start).toLocaleDateString()} - {new Date(form.schedule_end).toLocaleDateString()}</p>
                            ) : (
                                <p>Created {new Date(form.createdAt).toLocaleDateString()}</p>
                            )}
                            <div className="org-form-response-count">{form.response_count || 0} responses</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            {/* MAIN */}
            {organization && !organizationIsLoading &&
            <div className="organization-forms">
                <OrganizationSidebar/>
                <div className="org-forms-main">
                    <div className="org-forms-header">
                        <button className="org-forms-new-button" onClick={handleNewForm}>
                            <FaPlus className="org-forms-icon" />
                            <span className="org-forms-new-button-text">New Form</span>
                        </button>
                        
                        <div className="org-forms-filters">
                            <div className="org-forms-search">
                                <FaSearch className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search forms..."
                                    value={activeFilters.search}
                                    onChange={handleSearchChange}
                                    className="org-forms-search-input"
                                />
                            </div>
                            
                            <div className="view-toggle-buttons">
                                <button 
                                    className={`view-toggle-btn ${viewMode === 'grid' ? 'active' : ''}`}
                                    onClick={() => setViewMode('grid')}
                                    title="Grid View"
                                >
                                    <FaThLarge />
                                </button>
                                <button 
                                    className={`view-toggle-btn ${viewMode === 'list' ? 'active' : ''}`}
                                    onClick={() => setViewMode('list')}
                                    title="List View"
                                >
                                    <FaList />
                                </button>
                            </div>
                            
                            <div className="org-forms-status-dropdown" ref={statusDropdownRef}>
                                <button 
                                    className="status-dropdown-button"
                                    onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                                >
                                    Filter
                                    <FaChevronDown className={`dropdown-icon ${showStatusDropdown ? 'open' : ''}`} />
                                </button>
                                {showStatusDropdown && (
                                    <div className="status-dropdown-menu">
                                        {['Draft', 'Live', 'Closed'].map(status => (
                                            <button
                                                key={status}
                                                className={`status-dropdown-item ${activeFilters.status.includes(status) ? 'active' : ''}`}
                                                onClick={() => {
                                                    handleStatusFilter(status);
                                                }}
                                            >
                                                <span className="status-checkbox">
                                                    {activeFilters.status.includes(status) && <FaCheck />}
                                                </span>
                                                {status}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {(activeFilters.status.length > 0 || activeFilters.search) && (
                            <div className="active-filters">
                                {activeFilters.status.map(status => (
                                    <span key={status} className="filter-badge">
                                        Status: {status}
                                        <FaTimes
                                            className="remove-filter"
                                            onClick={() => removeFilter('status', status)}
                                        />
                                    </span>
                                ))}
                                {activeFilters.search && (
                                    <span className="filter-badge">
                                        Search: {activeFilters.search}
                                        <FaTimes
                                            className="remove-filter"
                                            onClick={() => removeFilter('search')}
                                        />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>

                    {showCreateNewForm && <ModalOrgNewForm createNewForm={submitNewForm} closeForm={() => setShowCreateNewForm(false)} />}
                    {activeFilters.status.length === 0 && !activeFilters.search ? (
                        <div className="org-forms-list">
                            <div className="org-forms-recent-section">
                                <div className="org-forms-status-header">
                                    <h2>Recently Modified</h2>
                                </div>
                                <div className="org-forms-recent-content">
                                    {getRecentlyModifiedForms().map((form) => {
                                        const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
                                        const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
                                        return (
                                            <div 
                                                key={form._id} 
                                                className="org-forms-item org-forms-recent-item" 
                                                onClick={(e) => {
                                                    if (!contextMenu.visible) {
                                                        navigate(`/o/${organization._id}/forms/${form._id}`)
                                                    }
                                                }}
                                                onContextMenu={(e) => handleContextMenu(e, form._id)}
                                            >
                                                {status === "Live" && <div className="org-forms-live-indicator"></div>}
                                                <span className={`form-status badge-${status.toLowerCase()}`}>{status}</span>
                                                <h3 className="form-title">{form.title}</h3>
                                                <p className="form-modified-time">
                                                    {formatDistanceToNow(new Date(form.updatedAt))} ago
                                                </p>
                                                <div className="org-form-response-count">{form.response_count || 0} responses</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            {Object.entries(groupedForms()).map(([status, statusForms]) => 
                                statusForms.length > 0 && (
                                    <div key={status} className="org-forms-status-section">
                                        <div 
                                            className="org-forms-status-header"
                                            onClick={() => toggleSection(status)}
                                        >
                                            <FaChevronRight 
                                                className={`section-chevron ${!collapsedSections[status] ? 'expanded' : ''}`}
                                            />
                                            <h2>{status}</h2>
                                            <span className="form-count">{statusForms.length}</span>
                                        </div>
                                        {!collapsedSections[status] && renderFormsList(statusForms)}
                                    </div>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="org-forms-list">
                            {renderFormsList(filteredForms)}
                        </div>
                    )}
                </div>
            </div>
            }
            {/* LOADING */}
            {organizationIsLoading &&
            <div style={{marginTop: "120px"}}>
                <Loader/>
            </div>
            }
            {contextMenu.visible && (
                <div 
                    ref={contextMenuRef}
                    className="context-menu"
                    style={{ position: 'fixed', top: contextMenu.y, left: contextMenu.x }}
                >
                    <button onClick={handleEditForm}>Edit form</button>
                    <button onClick={handleDuplicateForm}>Duplicate form</button>
                    <button onClick={handleDeleteForm}>Delete form</button>
                </div>
            )}
            {showDeleteForm && (
                <ModalOrgDeleteForm
                    closeModal={() => setShowDeleteForm(false)}
                    confirmDelete={confirmDeleteForm}
                    formTitle={formToDelete?.title}
                    isDeleting={isDeleting}
                />
            )}
        </div>
    )
}
