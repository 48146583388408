import React from 'react'
import './modals.css'
import { MdClose } from "react-icons/md";

export default function ModalWrapper({ children, close, title, subtitle }) {
    return (
        <div className="modals-base-wrapper">
            <div className="modals-overlay"></div>
            <div className="modals-content">
                <div className="modals-content-header">
                    <div className="modals-content-header-close" onClick={close}><MdClose /></div>
                    {title && <h2>{title}</h2>}
                </div>
                {subtitle && <div className="modals-content-subtitle">{subtitle}</div> }
                <div className="modals-content-spacer"></div>
                {children}
            </div>

        </div>
    )
}
