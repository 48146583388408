import React from 'react'
import './OrgLogo.css'

export default function OrgLogo({ logoUrl, organizationName, widthSizePixels = 600, heightSizePixels = "100%"  }) {
    const styles = {
        maxWidth: `${widthSizePixels}px`,
        maxHeight: `${heightSizePixels}px`,
        width: `${widthSizePixels}px`,
        height: `${heightSizePixels}px`
    }
    return (
        <div className="org-logo">
            {logoUrl &&
            <div className="org-logo-holder" style={styles}>
                <img src={logoUrl} alt="Tetherform Default Logo" />
            </div>
            }

            {!logoUrl && organizationName &&
            <div className="org-logo-holder" style={{backgroundImage: 'url("https://storage.googleapis.com/tetherform-organizations/tetherform_placeholder_background.jpeg")', ...styles }}>
                <span style={{fontSize: widthSizePixels/2}}>{organizationName[0]}</span>
            </div>
            }

            {!logoUrl && !organizationName &&
            <div className="org-logo-holder" style={styles}>
                <img src="https://storage.googleapis.com/tetherform-organizations/tetherform_placeholder_logo.jpeg" alt="Tetherform Default Logo" />
            </div>
            }
            
        </div>
    )
}
