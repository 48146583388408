import React, { useState, useEffect } from 'react'
import contentfulClient from '../../helpers/ContentfulApi';
import './Blog.css'
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../helpers/Helpers'
import Footer from '../footer/Footer';
import { FaHashtag } from "react-icons/fa6";

const POSTS_PER_PAGE = 20;

export default function BlogLanding() {
    const [posts, setPosts] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [filter, setFilter] = useState("all")

    const updatePosts = async (page = 1) => {
        try {
            setIsLoading(true)
            const skip = (page - 1) * POSTS_PER_PAGE;
            
            const response = await contentfulClient.getEntries({
                content_type: "blogPost",
                order: "-sys.createdAt",
                limit: POSTS_PER_PAGE,
                skip: skip
            });

            if (page === 1) {
                setPosts(response.items.map(item => item.fields))
            } else {
                setPosts(prev => [...prev, ...response.items.map(item => item.fields)])
            }

            setHasMore(response.total > skip + response.items.length)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error fetching blog posts:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const loadMore = () => {
        if (!isLoading && hasMore) {
            updatePosts(currentPage + 1)
        }
    }

    const updateMeta = () => {
        try {
            if (!posts || posts.length === 0) return

            // JSON-LD Data
            const itemListElement = posts.map((post, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://www.tetherform.com/blog/${post.urlSlug}`
            }));
            
            updateSchemaOrgJSONLD({
                "@context": "http://schema.org",
                "@type": "ItemList",
                "itemListElement": itemListElement
            })

            // Meta
            let title = "Blog | Tetherform"
            let metaTags = [
                { name: 'description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'og:url', content: 'https://tetherform.com/blog' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
            ]
            updateHeadTags(title, metaTags)
        } catch (error) {
            console.error('Error updating meta tags:', error)
        }
    }

    useEffect(() => {
        updatePosts(1)
        updateHeadTags("Blog | Tetherform")
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [posts])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="blog">
            <div className="blog-landing-heading">
                <div className="blog-landing-heading-inner">
                    <h1>Modern Digital Library</h1>
                    <p>A blog dedicated to the profession of improvement</p>
                    <div className="blog-landing-header-navs">
                        <span className={filter === "all" && "active"} onClick={() => setFilter("all")}>All</span>
                        <span className={filter === "event" && "active"} onClick={() => setFilter("event")}>Event Planning</span>
                        <span className={filter === "association" && "active"} onClick={() => setFilter("association")}>Professional Association</span>
                        <span className={filter === "surveys" && "active"} onClick={() => setFilter("surveys")}>Survey Tips & Tricks</span>
                    </div>
                </div>
            </div>
            <div className="blog-landing-list">
                {posts.map((post) => (
                    <article key={post.urlSlug} className="blog-landing-list-content">
                        {post.heroImage && (
                            <a 
                                href={`/blog/${post.urlSlug}`}
                                className="blog-landing-list-content-image"
                            >
                                <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                            </a>
                        )}
                        <div className="blog-landing-list-content-text">
                            <div className="blog-landing-list-content-categories">
                                {post.contentCategories.map((categoryName) => (
                                    <span key={categoryName}><FaHashtag/>{categoryName}</span>
                                ))}
                            </div>
                            <a 
                                href={`/blog/${post.urlSlug}`}
                                className="blog-landing-list-content-title"
                            >
                                <h2>{post.blogTitle}</h2>
                            </a>
                            <div className="blog-landing-list-content-date">
                                {formatDate(post.published)}
                            </div>
                            <div className="blog-landing-list-content-tagline">{post.tagline}</div>
                            <div className="blog-landing-list-content-spacer"></div>
                            <a 
                                href={`/blog/${post.urlSlug}`}
                                className="blog-landing-list-content-cta"
                            >
                                Read More →
                            </a>
                        </div>
                    </article>
                ))}
                
                {hasMore && (
                    <div className="blog-landing-load-more">
                        <button 
                            onClick={loadMore} 
                            disabled={isLoading}
                            className="blog-landing-load-more-button"
                        >
                            {isLoading ? 'Loading...' : 'Load More Posts'}
                        </button>
                    </div>
                )}
            </div>
            <Footer/>
        </div>
    )
}
