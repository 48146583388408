import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getFormPreview } from '../../api';
import Loader from '../loaders/Loader';
import './TheForm.css';
import TheForm from './TheForm';

export default function TheFormPreview() {
    const { organizationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState(null);
    const { formId } = useParams();

    useEffect(() => {
        const fetchForm = async () => {
            try {
                const response = await Promise.race([
                    getFormPreview(formId, organizationId),
                    new Promise((_, reject) => 
                        setTimeout(() => reject(new Error('Request timed out')), 5000)
                    )
                ]);
                setFormData(response.data.form);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching form:', error);
                setError('Unable to load the form. Please try again later.');
                setLoading(false);
            }
        };

        fetchForm();
    }, [formId, organizationId]);
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return (
            <div className="the-form-error-container">
                <p>{error}</p>
                <Link to="/" className="the-form-home-button">Return Home</Link>
            </div>
        );
    }
    if (formData && !loading) {
        return <TheForm formData={formData} preview={true} formId={formId} />;
    }

    return null;
}
