import axios from 'axios';

// Determine the base URL based on the environment
const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://tetherform.com/api'; // Replace with your production API URL
  }
  return 'http://localhost:5000/api'; // Default to localhost for development
};

let headers = {
  Accept: "application/json",

};
const api = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  headers: headers
});

// Password reset
export const requestPasswordReset = (email) => api.post('/reset-password/request', { email });
export const submitPasswordReset = (email, password, token) => api.post('/reset-password/submit', { email, password, token });

// User Management
export const registerUser = (email, password) => api.post('/auth/register', { email, password });

// Account Management
export const initializeOrganization = (user_name, organization_name, plan_name, is_annual_plan ) => api.post('/v1/accounts/init', { user_name, organization_name, plan_name, is_annual_plan });
export const getAccountManagementUrl = (organization_id) => api.get(`/v1/accounts/plan/${organization_id}`);

export const getOrganizationDetails = (organizationId) => api.get(`/v1/organizations/${organizationId}`);

// Form Management
export const createNewForm = (organizationId, formData) => api.post(`/v1/forms/${organizationId}`, formData);
export const updateFormDraft = (formId, updateData) => api.put(`/v1/forms/${formId}/draft`, updateData);
export const resetFormDraft = (formId) => api.post(`/v1/forms/${formId}/reset-draft`);
export const getFormDraft = (formId) => api.get(`/v1/forms/${formId}/draft`);
export const getFormResponses = (formId) => api.get(`/v1/forms/${formId}/responses`);
export const duplicateForm = (formId) => api.get(`/v1/forms/${formId}/duplicate`);
export const broadcastAnalytics = (formId) => api.post(`/v1/analytics/broadcast-analytics/${formId}`);


// Public
export const getPublicForm = (formId) => api.get(`/v1/public/form/${formId}`);
export const getFormPreview = (formId, orgId) => api.get(`/v1/public/preview/${orgId}/${formId}`);

// Response
export const submitFormResponse = (formId, answers, otherMetrics) => api.post(`/v1/public/form/${formId}/submit`, { answers, otherMetrics });

// Organization Forms
export const getOrganizationForms = (organizationId) => api.get(`/v1/forms/${organizationId}`);

// Group Poll Management
export const createGroupPoll = (pollData) => api.post('/v1/polls', pollData);
export const getGroupPoll = (pollId) => api.get(`/v1/polls/${pollId}`);
export const getGroupPollById = (objectId) => api.get(`/v1/polls/id/${objectId}`);
export const addPollResponse = (pollId, responseData) => api.post(`/v1/polls/${pollId}/responses`, responseData);
export const updatePollResponse = (pollId, email, updatedData) => api.put(`/v1/polls/${pollId}/responses/${email}`, updatedData);
export const getOrganizerPolls = (email) => api.get(`/v1/polls/organizer/${email}`);
export const updatePollStatus = (pollId, status) => api.put(`/v1/polls/${pollId}/status`, { status });

// Add to Group Poll Management section
export const sendCalendarLinks = (objectId, selectedTimeSlot) => api.post(`/v1/polls/${objectId}/calendar-links`, { selectedTimeSlot });

// Simple Tools
export const requestQrCode = (payload) => api.post(`/v1/tools/qr-code-generator`, payload);

// Form Templates
export const getFormTemplates = () => api.get('/v1/forms/templates');
export const createFormFromTemplate = (organizationId, templateId) => api.post(`/v1/forms/${organizationId}/template`, { templateId });

// Organization Logo
export const updateOrganizationLogo = (formData, organizationId) => {
  console.log(formData)
  return api.post(`/v1/organizations/${organizationId}/logo`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export default api;