import React, { useState, useEffect } from 'react'
import './ModalQuestionManage.css'
import { FaList, FaStar, FaCheckSquare, FaFont, FaChevronDown, FaHeading } from 'react-icons/fa'
import { IoClose, IoCheckboxSharp, IoSquareOutline } from "react-icons/io5";
import RatingOptions from './question-types/RatingOptions'
import MultipleChoiceOptions from './question-types/MultipleChoiceOptions'
import SelectMultipleOptions from './question-types/SelectMultipleOptions'
import TextOptions from './question-types/TextOptions'

const questionTypes = [
    { id: 'section-heading', icon: FaHeading, label: 'Section Heading' },
    { id: 'multiple-choice', icon: FaList, label: 'Multiple Choice' },
    { id: 'rating', icon: FaStar, label: 'Rating' },
    { id: 'select-multiple', icon: FaCheckSquare, label: 'Select Multiple' },
    { id: 'text', icon: FaFont, label: 'Text' },
];

const defaultRatingOptions = {
    min: 1,
    max: 5,
    symbol: 'star',
    minLabel: '',
    maxLabel: ''
};

const defaultMultipleChoiceOptions = {
    options: [],
    allowOther: false
};

const defaultSelectMultipleOptions = {
    options: [],
    allowOther: false
};

const defaultTextOptions = {
    size: 'single',
    maxLength: 280
};

export default function ModalQuestionManage({ onClose, questionType, existingQuestion, onSave }) {
    const [selectedType, setSelectedType] = useState(questionType || 'multiple-choice');
    const [question, setQuestion] = useState(existingQuestion?.question || '');
    const [description, setDescription] = useState(existingQuestion?.description || '');
    const [headingText, setHeadingText] = useState(existingQuestion?.content || '');
    const [headingDescription, setHeadingDescription] = useState(existingQuestion?.description || '');
    const [isRequired, setIsRequired] = useState(existingQuestion?.required || false);
    const [ratingOptions, setRatingOptions] = useState(
        existingQuestion?.scale || defaultRatingOptions
    );
    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(
        existingQuestion?.options || defaultMultipleChoiceOptions
    );
    const [selectMultipleOptions, setSelectMultipleOptions] = useState(
        existingQuestion?.options || defaultSelectMultipleOptions
    );
    const [textOptions, setTextOptions] = useState(
        existingQuestion?.textOptions || defaultTextOptions
    );

    useEffect(() => {
        if (existingQuestion) {
            setSelectedType(existingQuestion.type);
            if (existingQuestion.type === 'section-heading') {
                setHeadingText(existingQuestion.content || '');
                setHeadingDescription(existingQuestion.description || '');
            } else {
                setQuestion(existingQuestion.question || '');
                setDescription(existingQuestion.description || '');
                setIsRequired(existingQuestion.required);
                if (existingQuestion.type === 'rating') {
                    setRatingOptions(existingQuestion.scale || defaultRatingOptions);
                }
                if (existingQuestion.type === 'multiple-choice') {
                    setMultipleChoiceOptions(existingQuestion.options || defaultMultipleChoiceOptions);
                }
                if (existingQuestion.type === 'select-multiple') {
                    setSelectMultipleOptions(existingQuestion.options || defaultSelectMultipleOptions);
                }
                if (existingQuestion.type === 'text') {
                    setTextOptions(existingQuestion.textOptions || defaultTextOptions);
                }
            }
        } else if (questionType) {
            setSelectedType(questionType);
            if (questionType === 'rating') {
                setRatingOptions(defaultRatingOptions);
            } else if (questionType === 'multiple-choice') {
                setMultipleChoiceOptions(defaultMultipleChoiceOptions);
            } else if (questionType === 'select-multiple') {
                setSelectMultipleOptions(defaultSelectMultipleOptions);
            } else if (questionType === 'text') {
                setTextOptions(defaultTextOptions);
            }
        }
    }, [existingQuestion, questionType]);

    const handleSave = () => {
        if (selectedType === 'section-heading') {
            const headingData = {
                id: existingQuestion?.id || Date.now().toString(),
                type: 'section-heading',
                content: headingText,
                description: headingDescription || undefined
            };
            if (!headingText) return onClose()
            onSave(headingData);
            return;
        }

        const questionData = {
            id: existingQuestion?.id || Date.now().toString(),
            type: selectedType,
            question,
            description,
            required: isRequired,
        };

        if (selectedType === 'rating') {
            questionData.scale = ratingOptions;
        } else if (selectedType === 'multiple-choice' || selectedType === 'select-multiple') {
            questionData.options = selectedType === 'multiple-choice' ? multipleChoiceOptions : selectMultipleOptions;
        } else if (selectedType === 'text') {
            questionData.textOptions = textOptions;
        }

        onSave(questionData);
    };

    const SelectedIcon = questionTypes.find(type => type.id === selectedType)?.icon || FaList;

    return (
        <div className="modal-question-manage-overlay">
            <div className="modal-question-manage-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-question-manage-header">
                    <h3>{selectedType === 'section-heading' ? 'Section Heading' : 'Question'}</h3>
                    <button className="modal-question-manage-close modal-question-manage-close-create" onClick={onClose}>
                        <IoClose />
                    </button>
                </div>
                
                <div className="modal-question-manage-body">
                    <div className="modal-question-manage-main-information-container">
                        {selectedType === 'section-heading' ? (
                            <>
                                <div className="modal-question-manage-main-information-section">
                                    <label>Heading Text<span style={{color: "var(--color-red)"}}>*</span> </label>
                                    <input 
                                        type="text" 
                                        className="modal-question-manage-input" 
                                        placeholder="Enter section heading" 
                                        value={headingText} 
                                        onChange={(e) => setHeadingText(e.target.value)}
                                    />
                                </div>
                                <div className="modal-question-manage-main-information-section">
                                    <label>Description (optional)</label>
                                    <textarea 
                                        className="modal-question-manage-textarea" 
                                        placeholder="Add additional context or instructions..." 
                                        value={headingDescription} 
                                        onChange={(e) => setHeadingDescription(e.target.value)} 
                                        rows={4}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="modal-question-manage-main-information-section">
                                    <label>Question</label>
                                    <input 
                                        type="text" 
                                        className="modal-question-manage-input" 
                                        placeholder="What was your favorite part of the event?" 
                                        value={question} 
                                        onChange={(e) => setQuestion(e.target.value)}
                                    />
                                </div>

                                <div className="modal-question-manage-main-information-section">
                                    <label>Description (optional)</label>
                                    <textarea 
                                        className="modal-question-manage-textarea" 
                                        placeholder="e.g. Select all that apply" 
                                        value={description} 
                                        onChange={(e) => setDescription(e.target.value)} 
                                        rows={2} 
                                    />
                                </div>

                                <div className="modal-question-manage-main-information-section-row">
                                    <div className="modal-question-manage-type-section">
                                        <label>Question Type</label>
                                        <div className="modal-question-manage-selection-type-container">
                                            <div className="modal-question-manage-type-select-container">
                                                <SelectedIcon className="modal-question-manage-type-icon" />
                                                <select 
                                                    className="modal-question-manage-type-select" 
                                                    value={selectedType} 
                                                    onChange={(e) => setSelectedType(e.target.value)}
                                                >
                                                    {questionTypes.map((type) => (
                                                        <option key={type.id} value={type.id}>
                                                            {type.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <FaChevronDown className="modal-question-manage-type-select-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-question-manage-required-section">
                                        <label>Required</label>
                                        <div className="modal-question-manage-required">
                                            {!isRequired && <IoSquareOutline onClick={() => setIsRequired(true)}/>}
                                            {isRequired && <IoCheckboxSharp className="required" onClick={() => setIsRequired(false)}/>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    
                    {!selectedType.includes('section-heading') && (
                        <>
                            <div className="modal-question-manage-divider"></div>
                            {selectedType === 'rating' && (
                                <RatingOptions
                                    options={ratingOptions}
                                    onChange={setRatingOptions}
                                />
                            )}
                            {selectedType === 'multiple-choice' && (
                                <>
                                    <MultipleChoiceOptions
                                        options={multipleChoiceOptions.options}
                                        onChange={(newOptions) => setMultipleChoiceOptions({...multipleChoiceOptions, options: newOptions})}
                                    />
                                <div className="modal-question-manage-main-information-section" style={{marginTop: "1rem"}}> 
                                    <label>User-generated choices</label>
                                    <div className="multiple-choice-allow-other">
                                        <div className="modal-question-manage-required">
                                            {!multipleChoiceOptions.allowOther && <IoSquareOutline  onClick={() => setMultipleChoiceOptions({...multipleChoiceOptions, allowOther: true})}/>}
                                            {multipleChoiceOptions.allowOther && <IoCheckboxSharp className="required" onClick={() => setMultipleChoiceOptions({...multipleChoiceOptions, allowOther: false})}/>}
                                        </div>
                                        <label htmlFor="allow-other-checkbox">Allow user to provide an alternative choice</label>
                                    </div>
                                </div>
                                </>
                            )}
                            {selectedType === 'select-multiple' && (
                                <>
                                    <SelectMultipleOptions
                                        options={selectMultipleOptions.options}
                                        onChange={(newOptions) => setSelectMultipleOptions({...selectMultipleOptions, options: newOptions})}
                                    />
                                    <div className="modal-question-manage-main-information-section" style={{marginTop: "1rem"}}> 
                                        <label>User-generated choices</label>
                                        <div className="multiple-choice-allow-other">
                                            <div className="modal-question-manage-required">
                                                {!selectMultipleOptions.allowOther && <IoSquareOutline onClick={() => setSelectMultipleOptions({...selectMultipleOptions, allowOther: true})}/>}
                                                {selectMultipleOptions.allowOther && <IoCheckboxSharp className="required" onClick={() => setSelectMultipleOptions({...selectMultipleOptions, allowOther: false})}/>}
                                            </div>
                                            <label htmlFor="allow-other-checkbox">Allow user to provide an alternative choice</label>
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedType === 'text' && (
                                <TextOptions
                                    options={textOptions}
                                    onChange={setTextOptions}
                                />
                            )}
                        </>
                    )}
                </div>

                <div className="modal-question-manage-footer">
                    <button className="modal-question-manage-save" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}