import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Company.css';
import Footer from '../footer/Footer';
import { FaRocket, FaChartLine, FaUsers } from 'react-icons/fa';

export default function AboutUs() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "About Tetherform";
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="about-us-container">
            <div className="about-us-mission">
                <div className="about-us-mission-content">
                    <h2>Our Mission</h2>
                    <p>
                        Tetherform exists to transform how professional communities gather and respond to member feedback.
                    </p>
                    
                    <div className="about-us-values">
                        <div className="about-us-value-card">
                            <FaRocket className="about-us-value-icon" />
                            <h3>Innovation First</h3>
                            <p>We're constantly pushing the boundaries of what's possible with technology and community engagement.</p>
                        </div>
                        <div className="about-us-value-card">
                            <FaChartLine className="about-us-value-icon" />
                            <h3>Data-Driven Decisions</h3>
                            <p>We empower communities to make strategic choices based on real member insights.</p>
                        </div>
                        <div className="about-us-value-card">
                            <FaUsers className="about-us-value-icon" />
                            <h3>Community Focused</h3>
                            <p>Everything we build is designed to strengthen the bonds within professional communities.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-story">
                <div className="about-us-story-content">
                    <div className="about-us-story-text">
                        <h2>Our Story</h2>
                        <p>
                            Born from the challenges faced by professional community leaders, Tetherform 
                            was created to solve the common problem of turning member feedback into 
                            actionable insights. We recognized that while gathering feedback was easy, 
                            making sense of it was time-consuming and often overwhelming.
                        </p>
                        <p>
                            Today, we're proud to serve community leaders across various industries, 
                            helping them build stronger, more engaged communities through automated 
                            insights and strategic recommendations.
                        </p>
                    </div>
                </div>
            </div>

            <div className="about-us-founder">
                <div className="about-us-founder-content">
                    <div className="about-us-founder-image">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/landing/20240827KevinAFischerHeadshot.png" 
                            alt="Kevin Fischer - Founder of Tetherform" 
                        />
                    </div>
                    <div className="about-us-founder-text">
                        <h2>Meet Our Founder</h2>
                        <h3>Kevin Fischer</h3>
                        <p>Kevin runs Tetheros, a software company built to accelerate productive teamwork with innovative digital solutions.</p>
                        <p>As an avid volunteer with various events and programs, he noticed how hard it was for leaders to make good decisions from post-event surveys. After meeting with his team, they decided to help associations and communities make better decisions for their members by way of more intelligent surveys.</p>
                        <p>He also runs Armatage Candle Company (the world's greatest candle making education resource) and HorrrorVerse, a movie discovery and rating platform for fans of scary movies.</p>
                    </div>
                </div>
            </div>

            <div className="about-us-cta">
                <h2>Ready to transform your community feedback?</h2>
                <p>Join the growing number of communities using Tetherform to make better decisions.</p>
                <button className="about-us-cta-button" onClick={() => navigate('/register')}>
                    Start Your Free Trial
                </button>
            </div>

            <Footer />
        </div>
    );
}
