import React from 'react'
import { FaWpforms, FaChartBar, FaBell, FaPalette, FaLock, FaRegClock } from 'react-icons/fa';
import './HomeFeatures.css'

export default function HomeFeatures() {
    return (
        <div className="home-features-section">
        <div className="home-features-grid">

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaChartBar />
                </div>
                <h3>Smart Analytics</h3>
                <p>Visualize trends and patterns with intelligent data analysis and reporting</p>
            </div>

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaBell />
                </div>
                <h3>Instant Notifications</h3>
                <p>Get real-time alerts for new responses and automated insight reports</p>
            </div>

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaPalette />
                </div>
                <h3>Custom Branding</h3>
                <p>Match your brand with customizable colors, logos, and styling options</p>
            </div>

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaLock />
                </div>
                <h3>Secure Data</h3>
                <p>Enterprise-grade security to protect your community's feedback and insights</p>
            </div>

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaRegClock />
                </div>
                <h3>Scheduled Reports</h3>
                <p>Automated survey reporting & response notifications directly to your inbox</p>
            </div>

            <div className="home-feature-block">
                <div className="home-feature-icon">
                    <FaWpforms />
                </div>
                <h3>Form Templates</h3>
                <p>Start faster with pre-built templates for common community surveys</p>
            </div>
        </div>
    </div>
    )
}
