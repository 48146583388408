exports.formatUSDCurrency = (amount, min=2, max=2) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: min,
      maximumFractionDigits: max
    }).format(amount);
}

exports.formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
}

exports.isNonViewingRole = (roleName) => {
  try {
    const NONVIEWABLE_ROLENAMES = ['editor', 'admin']
    if (NONVIEWABLE_ROLENAMES.includes(roleName)) return true
    return false
  } catch (error) {
    return false
  }
}

exports.publishedFormHasBasicDifferences = (existing, incoming) => {
  // Early return if either object is invalid
  if (!existing || !incoming) return true;

  try {
    // Check basic form properties
    if (existing.design_primary_color !== incoming.design_primary_color ||
        existing.design_dark_mode !== incoming.design_dark_mode ||
        existing.design_show_logo !== incoming.design_show_logo ||
        existing.form_display_name !== incoming.form_display_name ||
        existing.introduction_message !== incoming.introduction_message) {
      return true;
    }

    const existingStructure = existing.structure;
    const incomingStructure = incoming.structure;

    // Check if both structures are valid arrays
    if (!Array.isArray(existingStructure) || !Array.isArray(incomingStructure)) {
      return true;
    }

    // Check if lengths differ
    if (existingStructure.length !== incomingStructure.length) {
      return true;
    }

    // Compare each element in the structure
    for (let i = 0; i < existingStructure.length; i++) {
      const existingElement = existingStructure[i];
      const incomingElement = incomingStructure[i];

      // Check basic properties that all elements share
      if (existingElement.id !== incomingElement.id ||
          existingElement.type !== incomingElement.type) {
        return true;
      }

      // Check type-specific properties
      switch (existingElement.type) {
        case 'section-heading':
          if (existingElement.content !== incomingElement.content ||
              existingElement.description !== incomingElement.description) {
            return true;
          }
          break;

        case 'multiple-choice':
        case 'select-multiple':
          if (existingElement.question !== incomingElement.question ||
              existingElement.description !== incomingElement.description ||
              existingElement.required !== incomingElement.required ||
              existingElement.options?.allowOther !== incomingElement.options?.allowOther) {
            return true;
          }
          // Compare options arrays
          const existingOptions = existingElement.options?.options || [];
          const incomingOptions = incomingElement.options?.options || [];
          if (existingOptions.length !== incomingOptions.length ||
              !existingOptions.every((opt, index) => opt === incomingOptions[index])) {
            return true;
          }
          break;

        case 'rating':
          if (existingElement.question !== incomingElement.question ||
              existingElement.description !== incomingElement.description ||
              existingElement.required !== incomingElement.required) {
            return true;
          }
          // Compare scale properties
          const existingScale = existingElement.scale || {};
          const incomingScale = incomingElement.scale || {};
          if (existingScale.min !== incomingScale.min ||
              existingScale.max !== incomingScale.max ||
              existingScale.symbol !== incomingScale.symbol ||
              existingScale.minLabel !== incomingScale.minLabel ||
              existingScale.maxLabel !== incomingScale.maxLabel) {
            return true;
          }
          break;

        case 'text':
          if (existingElement.question !== incomingElement.question ||
              existingElement.description !== incomingElement.description ||
              existingElement.required !== incomingElement.required) {
            return true;
          }
          // Compare text options
          const existingTextOpts = existingElement.textOptions || {};
          const incomingTextOpts = incomingElement.textOptions || {};
          if (existingTextOpts.size !== incomingTextOpts.size ||
              existingTextOpts.maxLength !== incomingTextOpts.maxLength) {
            return true;
          }
          break;

        default:
          // For any unhandled types, compare all properties
          if (JSON.stringify(existingElement) !== JSON.stringify(incomingElement)) {
            return true;
          }
      }
    }

    // If we get here, no differences were found
    return false;

  } catch (error) {
    console.error('Error comparing forms:', error);
    return true; // Return true on error to be safe
  }
};

exports.getContrastColor = (hexColor) => {
  // Remove the hash if it's there
  hexColor = hexColor.replace('#', '');

  // Convert to RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for bright colors, white for dark colors
  return luminance > 0.5 ? 'var(--color-dark)' : 'var(--color-white-pure)';
};

exports.updateCanonicalTag = (canonicalUrl) => {
  try {
      // Remove all existing <link rel="canonical"> tags
      const existingCanonicalLinks = document.querySelectorAll('link[rel="canonical"]');
      existingCanonicalLinks.forEach(link => link.parentNode.removeChild(link));

      // Create and append a new <link rel="canonical"> tag
      const canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
  } catch (error) {
      console.error("Error updating canonical tag:", error);
  }
};

exports.updateHeadTags = (title, metaTags) => {
  try {
      if (title) document.title = title;

      // Default to an empty array if metaTags is undefined
      const providedMetaTags = metaTags || [];

      // Only proceed if there are new meta tags to apply
      if (providedMetaTags.length === 0) {
          const keepMetaTags = ['charset', 'viewport', 'X-UA-Compatible', 'theme-color'];
          const existingMetaTags = document.querySelectorAll('meta');

          existingMetaTags.forEach(meta => {
              const name = meta.getAttribute('name') || meta.getAttribute('http-equiv') || meta.getAttribute('property');
              if (!keepMetaTags.includes(name)) meta.parentNode.removeChild(meta);
          });

          this.removeSchemaOrgJSONLD();
          return;
      }

      const head = document.getElementsByTagName('head')[0];

      // Loop over the provided meta tags to either update or create each one
      providedMetaTags.forEach(tag => {
          // Remove existing meta tag if it exists
          const existingTag = document.querySelector(`meta[name="${tag.name}"]`) || 
                             document.querySelector(`meta[property="${tag.name}"]`);
          if (existingTag) {
              existingTag.parentNode.removeChild(existingTag);
          }

          // Create new meta tag
          const metaElement = document.createElement('meta');
          if (tag.name.startsWith('og:') || tag.name.startsWith('twitter:')) {
              metaElement.setAttribute('property', tag.name); // For Open Graph/Twitter tags
          } else {
              metaElement.setAttribute('name', tag.name); // For other meta tags
          }
          metaElement.setAttribute('content', tag.content);
          head.appendChild(metaElement);
      });
  } catch (error) {
      console.error("Error updating head tags:", error);
      return;
  }
};

exports.updateSchemaOrgJSONLD = (data) => {
  try {
      const scriptId = 'schema-org-json-ld'
      let script = document.getElementById(scriptId);
      if (!script) {
        script = document.createElement('script');
        script.type = 'application/ld+json';
        script.id = scriptId;
        document.getElementsByTagName('head')[0].appendChild(script);
      }
      script.textContent = JSON.stringify(data);
  } catch (error) {
      return
  }
}

exports.removeSchemaOrgJSONLD = () => {
  try {
      const scriptId = 'schema-org-json-ld'
      const script = document.getElementById(scriptId)
      if (script) script.parentNode.removeChild(script)
  } catch (error) {
      return
  }

}