import React from 'react'
import './ModalQuestionManage.css'
import { FaList, FaStar, FaCheckSquare, FaFont, FaHeading } from 'react-icons/fa'

const questionTypes = [
    { id: 'section-heading', icon: FaHeading, label: 'Section Heading' },
    { id: 'multiple-choice', icon: FaList, label: 'Multiple Choice' },
    { id: 'rating', icon: FaStar, label: 'Rating' },
    { id: 'select-multiple', icon: FaCheckSquare, label: 'Select Multiple' },
    { id: 'text', icon: FaFont, label: 'Text' },
];

export default function AddQuestionFloatingModal({ onSelectQuestionType, style }) {
    return (
        <div className="add-question-floating-menu" style={style}>
            <div className="add-question-floating-menu-header">Select a question type</div>
            {questionTypes.map((type) => (
                <div 
                    key={type.id} 
                    className="add-question-floating-menu-item"
                    onClick={() => onSelectQuestionType(type.id)}
                >
                    <type.icon className="add-question-floating-menu-icon" />
                    <span className="add-question-floating-menu-label">{type.label}</span>
                </div>
            ))}
        </div>
    )
}
