import React, { useMemo } from 'react';
import './WeeklyHeatmap.css';

export default function WeeklyHeatmap({ timeseriesData }) {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const timeSlots = ['12am', '4am', '8am', '12pm', '4pm', '8pm'];

    const heatmapData = useMemo(() => {
        // Initialize grid with zeros
        const grid = Array(6).fill().map(() => Array(7).fill(0));
        
        // Process timestamps
        timeseriesData.forEach(timestamp => {
            const date = new Date(timestamp);
            
            // Get day (0 = Monday, 6 = Sunday)
            let day = date.getDay();
            // Convert Sunday from 0 to 6
            day = day === 0 ? 6 : day - 1;
            
            // Get hour and calculate time slot (0-5)
            const hour = date.getHours();
            const timeSlot = Math.floor(hour / 4);
            
            // Increment count for this cell
            grid[timeSlot][day]++;
        });

        // Find maximum value for scaling
        const maxValue = Math.max(...grid.flat());

        return {
            grid,
            maxValue
        };
    }, [timeseriesData]);

    const getCellStyle = (value) => {
        const intensity = value / heatmapData.maxValue;
        return {
            backgroundColor: `var(--color-brand)`,
            opacity: intensity || 0.1
        };
    };

    return (
        <div className="weekly-heatmap-container">
            <div className="weekly-heatmap-grid">
                {/* Time labels */}
                <div className="weekly-heatmap-time-labels">
                    {timeSlots.map((slot, index) => (
                        <div key={index} className="weekly-heatmap-time-label">
                            {slot}
                        </div>
                    ))}
                </div>

                {/* Main grid */}
                <div className="weekly-heatmap-main">
                    {/* Day labels */}
                    <div className="weekly-heatmap-day-labels">
                        {days.map((day, index) => (
                            <div key={index} className="weekly-heatmap-day-label">
                                {day}
                            </div>
                        ))}
                    </div>

                    {/* Cells */}
                    <div className="weekly-heatmap-cells">
                        {heatmapData.grid.map((row, timeIndex) => (
                            <div key={timeIndex} className="weekly-heatmap-row">
                                {row.map((value, dayIndex) => (
                                    <div
                                        key={dayIndex}
                                        className="weekly-heatmap-cell"
                                        style={getCellStyle(value)}
                                        title={`${days[dayIndex]} ${timeSlots[timeIndex]}: ${value} events`}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
