import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

export default function ComparisonSection({ 
    title, 
    subtitle,
    features, 
    backgroundColor = 'var(--color-dark)',
    textColor = 'var(--color-white)',
    competitorName = 'The Competition'
}) {
    return (
        <div 
            className="comparison-page-section" 
            style={{ 
                backgroundColor,
                color: textColor,
                width: '100%',
                margin: 0,
                padding: '120px 20px',
            }}
        >
            <div className="comparison-page-section-content">
                <h2 style={{ color: textColor }}>{title}</h2>
                {subtitle && (
                    <p className="comparison-page-section-subtitle" style={{ color: textColor }}>
                        {subtitle}
                    </p>
                )}
                <div className="comparison-page-features-grid">
                    <div className="comparison-page-column-headers">
                        <div className="comparison-page-feature-header">Feature</div>
                        <div className="comparison-page-platform-header">Tetherform</div>
                        <div className="comparison-page-platform-header">{competitorName}</div>
                    </div>
                    {features.map((feature, index) => (
                        <div key={index} className="comparison-page-feature-row">
                            <div className="comparison-page-feature-name">
                                <h3 style={{ color: textColor }}>{feature.name}</h3>
                                <p>{feature.description}</p>
                            </div>
                            <div className="comparison-page-feature-value">
                                {feature.tetherform ? (
                                    <FaCheck className="comparison-page-check" />
                                ) : (
                                    <FaTimes className="comparison-page-times" />
                                )}
                                {feature.tetherformNote && (
                                    <span>{feature.tetherformNote}</span>
                                )}
                            </div>
                            <div className="comparison-page-feature-value">
                                {feature.competitor ? (
                                    <FaCheck className="comparison-page-check" />
                                ) : (
                                    <FaTimes className="comparison-page-times" />
                                )}
                                {feature.competitorNote && (
                                    <span>{feature.competitorNote}</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
} 