import React from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';

export default function RatingComparison({ ratings }) {
    const renderStars = (score) => {
        const stars = [];
        const fullStars = Math.floor(score);
        const hasHalfStar = score % 1 >= 0.5;

        for (let i = 0; i < fullStars; i++) {
            stars.push(<FaStar key={`full-${i}`} />);
        }
        if (hasHalfStar) {
            stars.push(<FaStarHalf key="half" />);
        }

        return stars;
    };

    return (
        <div className="comparison-page-ratings">
            <div className="comparison-page-ratings-grid">
                <div className="comparison-page-ratings-header">
                    <div className="comparison-page-ratings-platform">Category</div>
                    <div className="comparison-page-ratings-platform">Tetherform</div>
                    <div className="comparison-page-ratings-platform">Competitor</div>
                </div>
                {Object.entries(ratings).map(([category, scores]) => (
                    <div key={category} className="comparison-page-ratings-row">
                        <div className="comparison-page-ratings-category">{category}</div>
                        <div className="comparison-page-ratings-score">
                            <div className="comparison-page-ratings-stars tetherform">
                                {renderStars(scores.tetherform)}
                            </div>
                            <span>{scores.tetherform.toFixed(1)}/5</span>
                        </div>
                        <div className="comparison-page-ratings-score">
                            <div className="comparison-page-ratings-stars competitor">
                                {renderStars(scores.competitor)}
                            </div>
                            <span>{scores.competitor.toFixed(1)}/5</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="comparison-page-ratings-source">
                Ratings from Capterra and G2 (Last updated: March 2024)
            </div>
        </div>
    );
} 