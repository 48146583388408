import React, { useState, useRef, useMemo, useEffect } from 'react';
import './QrCodeGenerator.css';
import { FaArrowLeft, FaQrcode, FaTimes } from 'react-icons/fa';
import { MdOutlineFileDownload } from "react-icons/md";
import { QRCodeSVG } from 'qrcode.react';
import { saveAs } from 'file-saver';
import Footer from '../../footer/Footer';
import { useNavigate } from 'react-router-dom';
import { requestQrCode } from '../../../api'
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../../helpers/Helpers'

export default function QrCodeGenerator() {
    const navigate = useNavigate()
    const [view, setView] = useState(1);
    const [webLink, setWebLink] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [email, setEmail] = useState('');
    const [selectedColor, setSelectedColor] = useState('#000000');
    const qrRef = useRef(null);
    const LOGO_SIZE = 50;
    const colors = [
        '#000000', '#FFFFFF', '#309C83', '#2563eb', 
        '#7c3aed', '#db2777', '#dc2626', '#ea580c',
        '#65a30d', '#0d9488', '#0284c7', '#6366f1',
        '#9333ea', '#c026d3', '#e11d48', '#f59e0b',
        '#475569', '#94a3b8', '#166534', '#0891b2',
        '#4338ca', '#86198f', '#9f1239',
        '#115e59', '#075985', '#4f46e5',
        '#7e22ce', '#a21caf', '#be123c', '#b45309'
    ];
    const logoDataUrl = useMemo(() => {
        const svgContent = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="100%" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
            <path fill="${selectedColor}" opacity="1.000000" stroke="none" 
                d="M220.000000,513.000000 C213.978378,513.000000 207.956741,513.000000 201.467560,512.531372 C201.000000,379.153107 201.000000,246.243546 201.000000,112.801682 C141.991104,112.801682 83.609871,112.801682 25.000000,112.801682 C25.000000,100.618744 24.872467,88.965981 25.033491,77.317207 C25.219114,63.888969 25.053446,50.414604 26.148260,37.051041 C27.412525,21.619158 37.237171,11.675835 50.132217,4.465096 C52.876053,2.930778 56.033245,2.135671 59.000000,1.000000 C193.354767,1.000000 327.709534,1.000000 462.678894,1.345447 C471.180176,3.698344 477.561310,7.805289 482.271088,14.598951 C488.731964,23.918526 489.669464,33.926373 488.188171,44.991901 C485.962067,61.620861 479.425995,76.089973 468.171265,88.145821 C452.272186,105.176613 432.222992,113.109573 408.900574,113.026260 C378.755005,112.918571 348.608765,113.000023 318.462799,113.000031 C316.712250,113.000031 314.961700,113.000023 313.000000,113.000023 C313.000000,115.729317 313.000000,117.715744 313.000000,119.702179 C313.000000,213.804169 313.013885,307.906158 312.958099,402.008118 C312.955383,406.603210 312.412323,411.204102 312.002350,415.791718 C309.904449,439.266541 299.893951,459.324493 284.599915,476.750275 C271.589203,491.574432 255.278000,501.783630 236.525574,507.972931 C231.059433,509.777069 225.510818,511.331268 220.000000,513.000000 z"/>
        </svg>`;
        return `data:image/svg+xml;base64,${btoa(svgContent)}`;
    }, [selectedColor]);
    const imageSettings = {
        src: logoDataUrl,
        height: LOGO_SIZE,
        width: LOGO_SIZE,
        excavate: true,
        opacity: 1
    };
    const handleGenerate = (e) => {
        e.preventDefault();
        if (!webLink) return;
        setView(2);
    };
    const downloadQRCode = () => {
        const svg = qrRef.current;
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const svgImg = new Image();
        const fileName = `qr-code.png`;

        // Only need to load the SVG since it already contains the logo
        svgImg.onload = () => {
            canvas.width = 1080;
            canvas.height = 1080;
            
            // Draw the QR code (which includes the logo)
            ctx.drawImage(svgImg, 0, 0, 1080, 1080);
            
            // Convert to blob and save
            canvas.toBlob((blob) => {
                saveAs(blob, fileName);
                setShowEmailModal(true);
            });
        };
        svgImg.src = "data:image/svg+xml;base64," + btoa(svgData);
    };
    const handleEmailSubmit = (e) => {
        e.preventDefault();
        requestQrCode({ survey_link: webLink, color: selectedColor, email: email })
        .then((res) => {
            console.log(res.data)
        })
        setShowEmailModal(false);
        setEmail('');
    };
    const updateMeta = () => {
        try {

            // JSON-LD Data
            updateSchemaOrgJSONLD({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "QR Code Generator",
                "description": "Free QR code generator for survey links",
                "url": "https://www.tetherform.com/qr-code-generator"
            })

            // Meta
            let title = "QR Code Generator | Tetherform"
            let metaTags = [
                { name: 'description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'og:url', content: 'https://tetherform.com/blog' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
            ]
            updateHeadTags(title, metaTags)
        } catch (error) {
            console.error('Error updating meta tags:', error)
        }
    }
    useEffect(() => {
        updateMeta()
    }, [])
    return (
        <div className="qr-code-g-outer-container">
            <div className="qr-code-g-container">
                <div className="qr-code-g-header">
                    <span className="qr-code-g-tag">Free Tool</span>
                    <h1>QR Code Generator</h1>
                    <p>Generate a QR code for your Google Forms or SurveyMonkey survey</p>
                </div>

                {view === 1 ? (
                    <div className="qr-code-g-view-1">
                        <form onSubmit={handleGenerate}>
                            <input
                                type="url"
                                value={webLink}
                                onChange={(e) => setWebLink(e.target.value)}
                                placeholder="Enter your survey link"
                                required
                            />
                            <button type="submit">
                                <FaQrcode /> Generate
                            </button>
                        </form>
                        <p className="qr-code-g-disclaimer">
                            This tool generates QR codes for web links. By using this tool, you consent to the temporary processing of your link to generate the QR code. We do not store any of your information beyond the generation process.
                        </p>
                    </div>
                ) : (
                    <div className="qr-code-g-view-2">
                        <div className="qr-code-g-link-row">
                            <FaArrowLeft 
                                className="qr-code-g-back" 
                                onClick={() => setView(1)} 
                            />
                            <input 
                                type="text" 
                                value={webLink} 
                                readOnly 
                            />
                        </div>

                        <div className="qr-code-g-editor">
                            <div className="qr-code-g-options">
                                <div className="qr-code-g-option-section">
                                    <h3>Customize the color</h3>
                                    <div className="qr-code-g-colors">
                                        {colors.map((color) => (
                                            <button
                                                key={color}
                                                className={`qr-code-g-color-option ${selectedColor === color ? 'selected' : ''}`}
                                                style={{ backgroundColor: color }}
                                                onClick={() => setSelectedColor(color)}
                                                aria-label={`Select ${color} color`}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="qr-code-g-option-section">
                                    <h3>Switch to Tetherform</h3>
                                    <p>Every plan includes free QR codes for every survey.</p>
                                    <div onClick={() => navigate('/register')} className="qr-code-g-option-section-button">Start a 14-day free trial</div>
                                </div>
                            </div>

                            <div className="qr-code-g-preview">
                                <div className="qr-code-g-code">
                                    <QRCodeSVG 
                                        value={webLink}
                                        size={300}
                                        level="H"
                                        includeMargin={true}
                                        ref={qrRef}
                                        fgColor={selectedColor}
                                        bgColor="transparent"
                                        imageSettings={imageSettings}
                                    />
                                </div>
                                <div className="qr-code-g-buttons">
                                    <button 
                                        className="qr-code-g-download-button"
                                        onClick={downloadQRCode}
                                    >
                                        <MdOutlineFileDownload />
                                        Download
                                    </button>
                                    <button 
                                        className="qr-code-g-remove-logo-button"
                                        onClick={() => setShowEmailModal(true)}
                                    >
                                        Remove Logo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showEmailModal && (
                    <div className="qr-code-g-modal-overlay">
                        <div className="qr-code-g-modal">
                            <FaTimes 
                                className="qr-code-g-modal-close" 
                                onClick={() => setShowEmailModal(false)} 
                            />
                            <h2>Ditch the branding</h2>
                            <p>Enter your email and we'll send the QR code without the logo to your inbox.</p>
                            <p>(Tetherform does this automatically for every survey)</p>
                            <form onSubmit={handleEmailSubmit}>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                                <button type="submit">Submit</button>
                                <button 
                                    type="button" 
                                    className="qr-code-g-modal-cancel"
                                    onClick={() => setShowEmailModal(false)}
                                >
                                    No thanks
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}