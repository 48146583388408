import React from 'react'
import { FaQuoteLeft, FaCircleCheck } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';
import FAQ from '../home/FAQ';
import '../home/home.css'

export default function ComparisonFooter() {
    const navigate = useNavigate()
    return (
        <>
        <div className="home-testimonial-container">
            <div className="home-testimonial-benefits">
                <h2>Are you ready to learn what your members think?</h2>
                <div className="home-testimonial-benefits-list">
                    <span><FaCircleCheck/> Try it free for 14 days</span>
                    <span><FaCircleCheck/> Cancel any time - no questions asked</span>
                    <span><FaCircleCheck/> Send your first survey in minutes</span>
                    <span><FaCircleCheck/> And understand your members!</span>
                </div>
            </div>

            <div className="home-testimonial-quote">
                <FaQuoteLeft/>
                <p>Tetherform lets us understand our members better by addressing all the shortcomings of StarChapter's built-in survey tool. Instead of a highly manual, time-consuming process to setup every survey (and even more time to read through the results), Tetherform lets us automate every step of the process. On top of that, its insights and recommendations give our chapter an opportunity to be proactive with our member's needs and desires!</p>
            </div>
        </div>
        <div className="home-testimonial-container" style={{margin: 0, paddingTop: 0, paddingBottom: "20px"}}>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <div className="home-primary-cta" style={{width: "unset"}}>
                    <div className="home-primary-cta-register" style={{color: "var(--color-white)"}} onClick={() => navigate('/register')}>Start a 14-day free trial</div>
                </div>
            </div>
        </div>
        <FAQ/>
        <div style={{fontSize: "1.2rem", marginTop: "80px"}}>Make better decisions for your organization</div>

        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
            <div className="home-primary-cta" style={{width: "unset"}}>
                <div className="home-primary-cta-register" onClick={() => navigate('/register')}>Start a 14-day free trial</div>
            </div>
        </div>
        </>
    )
}
