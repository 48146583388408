import React from 'react'
import { useNavigate } from 'react-router-dom'
import './footer.css'

export default function Footer() {
    const navigate = useNavigate()

    const footerSections = {
        Company: [
            { label: 'About us', href: '/about' },
            { label: 'Sitemap', href: '/sitemap.xml' },
            // { label: 'Contact us', path: '/contact' },
        ],
        Comparison: [
            { label: 'Google Forms', href: '/compare/google-forms' },
            // { label: 'Jotform', path: '/compare/jotform' },
            { label: 'SurveyMonkey', href: '/compare/survey-monkey' },
            // { label: 'Qualtrics', path: '/compare/qualtrics' },
        ],
        // Features: [
        //     { label: 'Surveys', path: '/features/surveys' },
        //     { label: 'Reports', path: '/features/reports' },
        //     { label: 'Analytics', path: '/features/analytics' },
        //     { label: 'Branding', path: '/features/branding' },
        // ],
        Resources: [
            { label: 'Blog', href: '/blog' },
            { label: 'QR Code Generator', href: '/qr-code-generator' },
            { label: 'Meeting Finder', href: '/meeting-finder' },
        ],
    }

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-navigation">
                    {Object.entries(footerSections).map(([section, links]) => (
                        <div key={section} className="footer-section">
                            <h3>{section}</h3>
                            <ul>
                                {links.map((link) => (
                                    <li key={link.path || link.href}>
                                        {link.href ? (
                                            <a 
                                                href={link.href}
                                            >
                                                {link.label}
                                            </a>
                                        ) : (
                                            <span onClick={() => navigate(link.path)}>
                                                {link.label}
                                            </span>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <p>© 2024 Tetherform. All rights reserved</p>
                    <p>Tetherform is a product of <a href="https://tetheros.com" target="_blank" rel="noopener noreferrer">Tetheros</a></p>
                </div>
            </div>
        </footer>
    )
}
