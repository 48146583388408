import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import './home.css'
import Footer from '../footer/Footer'
import { useAuth } from '../../context/AuthContext'
import FAQ from './FAQ';
import HomeFeatures from './HomeFeatures';
import { FaUsers, FaCalendarCheck, FaHandshake, FaPlay, FaTimes } from 'react-icons/fa';
import { FaQuoteLeft, FaCircleCheck } from 'react-icons/fa6'

export default function Home() {
    const navigate = useNavigate()
    const { isAuthenticated } = useAuth()
    const [showVideoModal, setShowVideoModal] = useState(false);

    const handlePlayVideo = () => {
        setShowVideoModal(true);
    };

    const handleCloseVideo = () => {
        setShowVideoModal(false);
    };
    useEffect(() => {
        document.title = "Tetherform"
    }, [])
    useEffect(() => {
        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;

        // Define the gtag_report_conversion function as part of the script content
        script.innerHTML = `
            window.gtag_report_conversion = function (url) {
                var callback = function () {
                    if (typeof(url) != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    'send_to': 'AW-16781787503/YDXwCLumzu4ZEO-Cl8I-',
                    'value': 1.0,
                    'currency': 'USD',
                    'event_callback': callback
                });
                return false;
            };
        `;

        // Append the script to the head
        document.head.appendChild(script);

        // Cleanup: Remove the script when the component unmounts
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    if (isAuthenticated) {
        return <Navigate to="/welcome" replace />
    }
    const handleRegistrationClick = () => {
        try {
            if (window.gtag_report_conversion) window.gtag_report_conversion()
        } catch (error) {
            console.log('Could not track conversion event')
        }
        navigate('/register')
    }
    return (
        <div className="home">
            <div className="home-hero-section">
                <div className="home-responsive-hero-container">
                    <div className="home-responsive-hero-primary">
                        <h1>Learn what your members want with surveys that help them feel seen</h1>
                        <p>Tetherform is a survey tool that helps your organization engage and retain members more effectively. We automatically analyze every response to provide personalized insights & recommendations so you can make decisions your community will love.</p>
                        {/* <p>Tetherform is a survey platform that automatically generates strategic recommendations from responses for your professional association so you can focus on members without straining your budget.</p> */}
                        {/* <p>Survey software that automatically transforms feedback into insights. Build surveys, gather member feedback, and instantly get strategic recommendations that help your professional community grow and thrive.</p> */}
                        <div className="home-primary-cta">
                            <div className="home-primary-cta-register" onClick={handleRegistrationClick}>Start a 14-day free trial</div>
                        </div>
                    </div>

                    <div className="home-hero-video-container">
                        <div className="home-hero-video">
                            <div className="home-hero-video-wrapper">
                                <img 
                                    src="https://storage.googleapis.com/tetheros-public/tetherform_landing/20241121_Tetherform_Insights.png" 
                                    alt="Tetherform Insights Demo"
                                    className="home-hero-image"
                                />
                                <div className="home-hero-play-overlay" onClick={handlePlayVideo}>
                                    <div className="home-hero-play-button">
                                        <FaPlay />
                                    </div>
                                    <span className="home-hero-play-text">Watch Demo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-why-choose-container">
                <h2>Designed for the things that matter</h2>
                <div className="home-why-choose-grid">
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaUsers />
                        </div>
                        <h3>Member Retention</h3>
                        <p>Keep members engaged by understanding and acting on their feedback automatically</p>
                    </div>
                    
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaCalendarCheck />
                        </div>
                        <h3>Event Participation</h3>
                        <p>Create events your community actually want to attend with personalized survey insights</p>
                    </div>
                    
                    <div className="home-why-choose-item">
                        <div className="home-why-choose-icon">
                            <FaHandshake />
                        </div>
                        <h3>Member Engagement</h3>
                        <p>Build deeper connections by identifying and addressing member needs proactively</p>
                    </div>
                </div>
            </div>

            <div className="home-social-proof-container">
                <p style={{textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>Trusted throughout the professional community</p>
                <div className="home-social-proof-names">
                    <div className="home-social-proof-logo">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/landing/tetheros_logo_blue.png" 
                            alt="Tetheros - Professional Community Platform" 
                        />
                    </div>
                    <div className="home-social-proof-logo">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/landing/logos/moonlite-logo-white.svg" 
                            alt="MoonLite - Digital Community Solutions" 
                        />
                    </div>
                    <div className="home-social-proof-logo">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/landing/logos/comment-pilgrim-color-logo.png" 
                            alt="Comment Pilgrim - Community Engagement Platform" 
                        />
                    </div>
                    <div className="home-social-proof-logo">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/landing/logos/armatage-candle-company-logo-icon-darl.png" 
                            alt="Armatage Candle Company - Luxury Candle Community" 
                        />
                    </div>
                </div>
            </div>

            <div className="home-section-landing" style={{ marginTop: "20px" }}>
                <h2>Simplify how you handle member feedback</h2>
            </div>

            <div className="home-section-landing-split">
                <div className="home-section-landing-split-texts">
                    <div className="home-section-landing-split-text-decoration"></div>
                    <h3>Effortlessly create surveys</h3>
                    <p>Whether you're gathering event feedback, tracking member satisfaction, or planning initiatives, Tetherform simplifies the process with customizable templates and a user-friendly interface - no technical expertise required.</p>
                </div>
                <div className="home-section-landing-split-visuals">
                    <div className="home-section-landing-split-visual-container">
                        <img style={{maxWidth: "400px"}} src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform_phone_white_1.png" alt="Tetherform Survey Light Mode" />
                    </div>
                </div>
            </div>

            <div className="home-section-landing-split reverse">
                <div className="home-section-landing-split-texts">
                    <div className="home-section-landing-split-text-decoration"></div>
                    <h3>Turn feedback into recommendations</h3>
                    <p>Tetherform automatically analyzes responses to highlight trends, uncover opportunities, and deliver tailored recommendations. Stop guessing what your members need—our platform gives you the clarity to make smarter, faster decisions for your community.</p>
                </div>
                <div className="home-section-landing-split-visuals">
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tf-analytics-2.png" alt="Fresh Tetherform Insights"/>
                </div>
            </div>

            <div className="home-section-landing-split">
                <div className="home-section-landing-split-texts">
                    <div className="home-section-landing-split-text-decoration"></div>
                    <h3>Automate your notifications</h3>
                    <p>Whether you're gathering event feedback, tracking member satisfaction, or planning initiatives, Tetherform simplifies the process with customizable templates and a user-friendly interface - no technical expertise required.</p>
                </div>
                <div className="home-section-landing-split-visuals">
                    <div className="home-section-landing-split-visual-container">
                        <img style={{maxWidth: "900px", objectPosition: "top left"}} src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform-notifications.png" alt="Tetherform notification settings" />
                    </div>
                </div>
            </div>

            <div className="home-section-understand home-section-landing">
                <div className="home-cta-doublewide">
                    <div className="home-primary-cta-register" onClick={handleRegistrationClick}>
                        Get started
                    </div>
                    <div 
                        className="home-primary-cta-register" 
                        onClick={handlePlayVideo} 
                        style={{backgroundColor: "var(--color-grey)"}}
                    >
                        Watch a tutorial
                    </div>
                </div>
            </div>

            <div className="home-analytics-section home-section-landing">
                <h2>Unrivaled Analytics</h2>
                <div className="home-analytics-image-container">
                    <a href="https://storage.googleapis.com/tetheros-public/tetherform_landing/2024%2011%2030%20tetherform_analytics_page.png" target="_blank" rel="noopener noreferrer">
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2024%2011%2030%20tetherform_analytics_page.png"
                            alt="Tetherform Analytics Dashboard"
                            className="home-analytics-image"
                        />
                    </a>
                </div>
            </div>


            <div className="home-section-landing">
                <h2>Stacked with tools for your community</h2>
            </div>
            <HomeFeatures/>

            <div className="home-testimonial-container">
                <div className="home-testimonial-benefits">
                    <h2>Are you ready to learn what your members think?</h2>
                    <div className="home-testimonial-benefits-list">
                        <span><FaCircleCheck/> Try it free for 14 days</span>
                        <span><FaCircleCheck/> Cancel any time - no questions asked</span>
                        <span><FaCircleCheck/> Send your first survey in minutes</span>
                        <span><FaCircleCheck/> And understand your members!</span>
                    </div>
                </div>

                <div className="home-testimonial-quote">
                    <FaQuoteLeft/>
                    <p>Tetherform lets us understand our members better by addressing all the shortcomings of StarChapter's built-in survey tool. Instead of a highly manual, time-consuming process to setup every survey (and even more time to read through the results), Tetherform lets us automate every step of the process. On top of that, its insights and recommendations give our chapter an opportunity to be proactive with our member's needs and desires!</p>
                </div>
            </div>
            <div className="home-testimonial-container" style={{margin: 0, paddingTop: 0, paddingBottom: "20px"}}>
                <div className="home-cta-doublewide">
                    <div className="home-primary-cta-register" onClick={handleRegistrationClick} style={{color: "var(--color-white)"}}>
                        Get started
                    </div>
                    <div 
                        className="home-primary-cta-register" 
                        onClick={() => navigate('/plans')} 
                        style={{ backgroundColor: "var(--color-grey)", color: "var(--color-white)" }}
                    >
                        View Pricing
                    </div>
                </div>



            </div>

            <FAQ/>
            <div style={{fontSize: "1.2rem", marginTop: "80px"}}>Make better decisions for your organization</div>

            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <div className="home-primary-cta" style={{width: "unset"}}>
                    <div className="home-primary-cta-register" onClick={handleRegistrationClick}>Start a 14-day free trial</div>
                </div>
            </div>

            <Footer/>

            {showVideoModal && (
                <div className="home-video-modal-overlay" onClick={handleCloseVideo}>
                    <button className="home-video-modal-close desktop-only" onClick={handleCloseVideo}>
                        <FaTimes />
                    </button>
                    <div className="home-video-modal-content" onClick={e => e.stopPropagation()}>
                        <iframe 
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube.com/embed/Q0u6wJSFkPE?si=TJ03ITlNE7NB29tA&autoplay=1" 
                            title="Tetherform Demo Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    )
}